<template>
  <b-button @click="$emit('on-click')" :disabled="disabled" :type="type">
    <font-awesome-icon v-if="clicked" icon="spinner" class="fa-spin" />
    <slot />
  </b-button>
</template>
<script>
export default {
  name: "ActionButton",
  props: ["clicked", "disabled", "type"],
};
</script>
<style lang="scss">
</style>