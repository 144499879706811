"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndividualCondition = void 0;
const class_validator_1 = require("class-validator");
const transfer_1 = require("./transfer");
const custodianValue_1 = require("./custodianValue");
class IndividualCondition {
    constructor(fields) {
        this.personalMessage = '';
        this.transferList = [];
        this.custodianValueList = [];
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'transferList':
                    this[key] = value.map((transfer) => new transfer_1.Transfer(transfer));
                    break;
                case 'custodianValueList':
                    this[key] = value.map((custodianValue) => new custodianValue_1.CustodianValue(custodianValue));
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            individualRemuneration: this.individualRemuneration,
            personalMessage: this.personalMessage,
            transferList: this.transferList.map((transfer) => transfer.toJSONObject()),
            custodianValueList: this.custodianValueList.map((custodianValue) => custodianValue.toJSONObject()),
        };
    }
    toDatabase() {
        return {
            individualRemuneration: this.individualRemuneration,
            personalMessage: this.personalMessage,
            transferList: this.transferList.map((transfer) => transfer.toDatabase()),
            custodianValueList: this.custodianValueList.map((custodianValue) => custodianValue.toDatabase()),
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)()
], IndividualCondition.prototype, "individualRemuneration", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], IndividualCondition.prototype, "personalMessage", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], IndividualCondition.prototype, "transferList", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], IndividualCondition.prototype, "custodianValueList", void 0);
exports.IndividualCondition = IndividualCondition;
