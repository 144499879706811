import Vue from "vue"
import BaseToast from '@/modules/global/components/BaseToast'

Vue.mixin({
  methods: {
    toast(title, message, success) {
      const content = {
        component: BaseToast,
        props: {
          title: title,
          message: message,
          success: success
        }
      }
      this.$toast(content, {
        position: "bottom-right",
        timeout: 5000,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.25,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: false,
        rtl: false
      });
    },
  },
});
const toastMixin = {}

export default toastMixin