import axios from 'axios'
import config from '../../../conf.json'
import { superModel } from '../../models/superModel'

const company = {
	namespaced: true,
	state: {
		companies: [],
		isReady: false,
	},
	mutations: {
		setCompanies(state, data) {
			state.companies = data
		},
		setIsReady(state, bool) {
			state.isReady = bool
		},
	},
	actions: {
		clearState({ commit }) {
			commit('setCompanies', [])
			commit('setIsReady', false)
		},
		async reloadCompanies({ commit, rootGetters, dispatch }) {
			commit('setIsReady', false)
			dispatch('company/getCompanies', null, { root: true })
		},
		async getCompanies({ state, commit, rootGetters }) {
			if (!state.isReady) {
				console.log('loading companies')
				let idToken = await rootGetters['auth/idToken']

				var options = {
					url: config.firebaseConfig.apiURL + '/company',
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
				try {
					var result = await axios(options)
					var arr = []
					for (const data of result.data) {
						var company = await superModel('company', data)
						arr.push(company)
					}
					commit('setCompanies', arr)
					commit('setIsReady', true)
				} catch (error) {
					console.log(error)
				}
			}
		},
	},
	getters: {
		isReady(state) {
			return state.isReady
		},
		companies(state) {
			return state.companies
		},
		getCompany: (state) => (id) => {
			return state.companies.find((company) => company.id === id)
		},
	},
}

export default company
