import i18n from '@/plugins/i18n';

// Login + signup

const Signup = () => import('@/modules/general/signup/views/Signup');


export default [
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        meta: {
            aciton: 'create',
            resource: 'User',
            title: i18n.t('browserTitle.signup')
        }
    },
]