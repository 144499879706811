import i18n from '@/plugins/i18n'

// Company
const CompanyList = () => import(/* webpackChunkName: 'CompanyList' */ '@/modules/general/company/views/CompanyList')
const CompanyAdd = () => import(/* webpackChunkName: 'CompanyAdd' */ '@/modules/general/company/views/CompanyAdd')
const CompanyInfo = () => import(/* webpackChunkName: 'Company' */ '@/modules/general/company/views/CompanyInfo')
const CompanyAdvisor = () => import(/* webpackChunkName: 'CompanyAdvisor' */ '@/modules/general/company/views/CompanyAdvisor')
const CompanyPension = () => import(/* webpackChunkName: 'CompanyPension' */ '@/modules/general/company/views/CompanyPension')
const CompanyInsurance = () => import(/* webpackChunkName: 'CompanyInsurance' */ '@/modules/general/company/views/CompanyInsurance')
const CompanyInsuranceList = () => import('@/modules/general/company/views/CompanyInsuranceList')
const CompanyPensionList = () => import('@/modules/general/company/views/CompanyPensionList')
const CompanyKycList = () => import('@/modules/general/company/views/CompanyKycList')
const CompanyKycAdd = () => import('@/modules/general/company/views/CompanyKycAdd')
const CompanyKycEdit = () => import('@/modules/general/company/views/CompanyKycEdit')

const CompanyGroup = () => import('@/modules/general/company/views/CompanyGroup')

const CompanyLogs = () => import(/* webpackChunkName: 'CompanyLogs' */ '@/modules/general/company/views/CompanyLogs')
const CompanySettings = () => import(/* webpackChunkName: 'CompanySettings' */ '@/modules/general/company/views/CompanySettings')

const CompanyEmployeeList = () => import(/* webpackChunkName: 'CompanyEmployeeList' */ '@/modules/general/company/views/CompanyEmployeeList')
const CompanyAdminList = () => import(/* webpackChunkName: 'CompanyAdminList' */ '@/modules/general/company/views/CompanyAdminList')

const EmployeeAdd = () => import('@/modules/general/company/views/EmployeeAdd')
const EmployeeAddAdmin = () => import('@/modules/general/company/views/EmployeeAddAdmin')

const CompanyAdminAdd = () => import('@/modules/general/company/views/CompanyAdminAdd')
const CompanyAdminSet = () => import('@/modules/general/company/views/CompanyAdminSet')

const CompanyEmployeeSet = () => import('@/modules/general/company/views/CompanyEmployeeSet')
const EmployeeImport = () => import(/* webpackChunkName: 'CompanyEmployeeImport' */ '@/modules/general/company/views/EmployeeImport')

const BaseCompany = () => import(/* webpackChunkName: 'BaseCompany' */ '@/modules/general/company/views/BaseCompany')

export default [
	{
		path: '/company/list',
		name: 'Companies',
		component: CompanyList,
		meta: {
			resource: 'Companies',
			action: 'read',
			requiresLoggedIn: true,
			title: i18n.t('browserTitle.companies.list'),
		},
	},
	{
		path: '/company/add',
		name: 'companyAdd',
		component: CompanyAdd,
		meta: {
			resource: 'Company',
			action: 'create',
			requiresLoggedIn: true,
			title: i18n.t('browserTitle.companies.add'),
		},
	},
	{
		path: '/company/:companyId/',
		name: 'BaseCompany',
		component: BaseCompany,
		children: [
			{
				path: 'info',
				name: 'Company',
				component: CompanyInfo,
				meta: {
					requiresLoggedIn: true,
					resource: 'Company',
					action: 'read',
					title: i18n.t('browserTitle.company.info') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'advisor',
				name: 'CompanyAdvisor',
				component: CompanyAdvisor,
				meta: {
					requiresLoggedIn: true,
					resource: 'Company',
					action: 'read',
					title: i18n.t('browserTitle.company.advisor') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'group',
				name: 'CompanyGroup',
				component: CompanyGroup,
				meta: {
					requiresLoggedIn: true,
					action: 'read',
					resource: 'Company',
					title: 'Gruppe',
				},
			},
			{
				path: 'pension/:pensionId',
				name: 'CompanyPension',
				component: CompanyPension,
				meta: {
					requiresLoggedIn: true,
					action: 'read',
					resource: 'Company',
					title: i18n.t('browserTitle.company.pension') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'insurance/:insuranceId',
				name: 'CompanyInsurance',
				component: CompanyInsurance,
				meta: {
					requiresLoggedIn: true,
					action: 'read',
					resource: 'Company',
					title: i18n.t('browserTitle.company.insurance') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'logs',
				name: 'CompanyLogs',
				component: CompanyLogs,
				meta: {
					resource: 'CompanyLogs',
					action: 'read',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.company.logs') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'settings',
				name: 'CompanySettings',
				component: CompanySettings,
				meta: {
					resource: 'CompanySettings',
					action: 'update',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.company.settings') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'insurance',
				name: 'CompanyInsuranceList',
				component: CompanyInsuranceList,
				meta: {
					resource: 'Company',
					action: 'read',
					requiresLoggedIn: true,
					title: 'Forsikringsaftaler',
				},
			},
			{
				path: 'pension',
				name: 'CompanyPensionList',
				component: CompanyPensionList,
				meta: {
					resource: 'Company',
					action: 'read',
					requiresLoggedIn: true,
					title: 'Pensionsaftaler',
				},
			},
			{
				path: 'employee/list',
				name: 'CompanyEmployeeList',
				component: CompanyEmployeeList,
				meta: {
					resource: 'Company',
					action: 'read',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.company.employees') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'kyc',
				name: 'CompanyKycList',
				component: CompanyKycList,
				meta: {
					resource: 'Company',
					action: 'read',
					requiresLoggedIn: true,
					title: "Ejerforhold",
				},
			},
			{
				path: 'kyc/add',
				name: 'CompanyKycAdd',
				component: CompanyKycAdd,
				meta: {
					resource: 'Company',
					action: 'read',
					requiresLoggedIn: true,
					title: "Tilføj KYC",
				},
			},
			{
				path: 'kyc/:index/edit',
				name: 'CompanyKycEdit',
				component: CompanyKycEdit,
				meta: {
					resource: 'Company',
					action: 'read',
					requiresLoggedIn: true,
					title: "Ændr KYC",
				},
			},
			{
				path: 'admin/list',
				name: 'CompanyAdminList',
				component: CompanyAdminList,
				meta: {
					resource: 'Company',
					action: 'read',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.company.companyAdmins') + ' | ' + i18n.t('browserTitle.companies.update'),
				},
			},
			{
				path: 'employee/register',
				name: 'CompanyEmployeeAdd',
				component: EmployeeAdd,
				meta: {
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employees.add'),
				},
			},
			{
				path: 'employee/registerAdmin',
				name: 'CompanyEmployeeAddAdmin',
				component: EmployeeAddAdmin,
				meta: {
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employees.add'),
				},
			},
			{
				path: 'companyAdmin/register',
				name: 'CompanyAdminAdd',
				component: CompanyAdminAdd,
				meta: {
					requiresLoggedIn: true,
					title: 'Opret virksomhedsadmin',
				},
			},
			{
				path: 'companyAdmin/set',
				name: 'CompanyAdminSet',
				component: CompanyAdminSet,
				meta: {
					requiresLoggedIn: true,
					title: 'Tilføj virksomhedsadmin',
				},
			},
			{
				path: 'employee/set',
				name: 'CompanyEmployeeSet',
				component: CompanyEmployeeSet,
				meta: {
					requiresLoggedIn: true,
					title: 'Tilføj medarbejder',
				},
			},
			{
				path: 'employee/import',
				name: 'CompanyEmployeeImport',
				component: EmployeeImport,
				meta: {
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employees.import'),
				},
			},
		],
	},
]
