import i18n from '@/plugins/i18n';

// Login
const Login = () => import('@/modules/general/login/views/Login');
const ForgotPassword = () => import('@/modules/general/login/views/ForgotPassword');

export default [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresNotLoggedIn: true,
            title: i18n.t('browserTitle.login')
        }
    },
    {
        path: '/forgot',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            requiresNotLoggedIn: true,
            title: i18n.t('browserTitle.forgot')
        }
    },
]