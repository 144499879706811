import Vue from 'vue'
import conf from '../../conf.json'
import { initializeApp } from 'firebase/app'

// firebase init - add your own config here
const firebaseConfig = conf.firebaseConfig
const firebaseApp = initializeApp(firebaseConfig)

Vue.use(firebaseApp)
export default firebaseApp
