"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Insurance = void 0;
const class_validator_1 = require("class-validator");
const healthInsurance_1 = require("./healthInsurance");
const healthInsurancePayment_1 = require("./healthInsurancePayment");
const standardPolicy_1 = require("./standardPolicy");
const uuid_1 = require("uuid");
class Insurance {
    constructor(fields) {
        this.supplierId = '';
        this.name = '';
        this.supplierPriceGroup = '';
        this.supplierTemplateId = '';
        this.healthInsuranceIsMandatory = false;
        this.workAbility = new standardPolicy_1.StandardPolicy({ title: 'Tab af erhvervsevne', type: 'percentage' });
        this.workAbilityPayment = new standardPolicy_1.StandardPolicy({ title: 'Udbetaling af forsikring ved tab af erhvervsevne' });
        this.deathSum = new standardPolicy_1.StandardPolicy({ title: 'Dødsfaldssum', type: 'percentage' });
        this.pensionContribution = new standardPolicy_1.StandardPolicy({ title: 'Præmiefritagelse for pensionsbidrag', type: 'percentage' });
        this.invalideSum = new standardPolicy_1.StandardPolicy({ title: 'Invalidesum', type: 'currency' });
        this.childPension = new standardPolicy_1.StandardPolicy({ title: 'Børnerente', type: 'percentage' });
        this.criticalDisease = new standardPolicy_1.StandardPolicy({ title: 'Visse kritiske sygdom', type: 'currency' });
        this.criticalDiseaseChild = new standardPolicy_1.StandardPolicy({ title: 'Visse kritiske sygdom børn', type: 'currency' });
        this.healthInsurance = new standardPolicy_1.StandardPolicy({ title: 'Sundhedsforsikring' });
        this.healthInsurancePayment = new healthInsurancePayment_1.HealthInsurancePayment({ title: 'Betaling via faktura eller integeret pension' });
        this.healthInsurances = [];
        this.healthInsuranceChild = new standardPolicy_1.StandardPolicy({ title: 'Sundhedsforsikring til børn' });
        this.healthInsurancePartner = new standardPolicy_1.StandardPolicy({ title: 'Sundhedsforsikring til medforsikrede' });
        this.supplementarySalary = new standardPolicy_1.StandardPolicy({ title: 'Tillægsløn er dækningsgivende', value: false });
        this.startDate = new standardPolicy_1.StandardPolicy({ title: 'Ikrafttrædelsesdato' });
        this.waitingPeriode = new standardPolicy_1.StandardPolicy({ title: 'Indmeldelseskriterier' });
        this.expirationDate = new standardPolicy_1.StandardPolicy({ title: 'Udløbsdato' });
        this.drawingWindow = new standardPolicy_1.StandardPolicy({ title: 'Tegningsvindue' });
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'workAbility':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'workAbilityPayment':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'pensionContribution':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'invalideSum':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'deathSum':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'childPension':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'criticalDisease':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'criticalDiseaseChild':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'healthInsurance':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'healthInsuranceChild':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'healthInsurancePartner':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'supplementarySalary':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'startDate':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'waitingPeriode':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'expirationDate':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'drawingWindow':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'healthInsurancePayment':
                    this[key] = new healthInsurancePayment_1.HealthInsurancePayment(value);
                    break;
                case 'healthInsurances':
                    this[key] = value.map((healthInsurance) => new healthInsurance_1.HealthInsurancePolicy(healthInsurance));
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    addHealthInsurance(title) {
        var uuid = (0, uuid_1.v4)();
        this.healthInsurances.push(new healthInsurance_1.HealthInsurancePolicy({ title: title, id: uuid }));
    }
    toJSONObject() {
        return {
            supplierId: this.supplierId,
            name: this.name,
            supplierPriceGroup: this.supplierPriceGroup,
            supplierTemplateId: this.supplierTemplateId,
            workAbility: this.workAbility.toJSONObject(),
            workAbilityPayment: this.workAbilityPayment.toJSONObject(),
            pensionContribution: this.pensionContribution.toJSONObject(),
            invalideSum: this.invalideSum.toJSONObject(),
            deathSum: this.deathSum.toJSONObject(),
            childPension: this.childPension.toJSONObject(),
            criticalDisease: this.criticalDisease.toJSONObject(),
            criticalDiseaseChild: this.criticalDiseaseChild.toJSONObject(),
            healthInsurance: this.healthInsurance.toJSONObject(),
            healthInsurancePayment: this.healthInsurancePayment.toJSONObject(),
            healthInsurances: this.healthInsurances.map((healthInsurance) => healthInsurance.toJSONObject()),
            healthInsuranceChild: this.healthInsuranceChild.toJSONObject(),
            healthInsurancePartner: this.healthInsurancePartner.toJSONObject(),
            supplementarySalary: this.supplementarySalary.toJSONObject(),
            startDate: this.startDate.toJSONObject(),
            waitingPeriode: this.waitingPeriode.toJSONObject(),
            expirationDate: this.expirationDate.toJSONObject(),
            drawingWindow: this.drawingWindow.toJSONObject(),
            healthInsuranceIsMandatory: this.healthInsuranceIsMandatory,
        };
    }
    toDatabase() {
        return {
            supplierId: this.supplierId,
            name: this.name,
            supplierPriceGroup: this.supplierPriceGroup,
            supplierTemplateId: this.supplierTemplateId,
            workAbility: this.workAbility.toDatabase(),
            workAbilityPayment: this.workAbilityPayment.toDatabase(),
            pensionContribution: this.pensionContribution.toDatabase(),
            invalideSum: this.invalideSum.toDatabase(),
            deathSum: this.deathSum.toDatabase(),
            childPension: this.childPension.toDatabase(),
            criticalDisease: this.criticalDisease.toDatabase(),
            criticalDiseaseChild: this.criticalDiseaseChild.toDatabase(),
            healthInsurance: this.healthInsurance.toDatabase(),
            healthInsurancePayment: this.healthInsurancePayment.toDatabase(),
            healthInsurances: this.healthInsurances.map((healthInsurance) => healthInsurance.toDatabase()),
            healthInsuranceChild: this.healthInsuranceChild.toDatabase(),
            healthInsurancePartner: this.healthInsurancePartner.toDatabase(),
            supplementarySalary: this.supplementarySalary.toDatabase(),
            startDate: this.startDate.toDatabase(),
            waitingPeriode: this.waitingPeriode.toDatabase(),
            expirationDate: this.expirationDate.toDatabase(),
            drawingWindow: this.drawingWindow.toDatabase(),
            healthInsuranceIsMandatory: this.healthInsuranceIsMandatory,
        };
    }
    clearComments() {
        this.waitingPeriode.comment = '';
        this.expirationDate.comment = '';
        this.drawingWindow.comment = '';
        this.expirationDate.comment = '';
        this.startDate.comment = '';
        this.supplementarySalary.comment = '';
        this.healthInsurancePartner.comment = '';
        this.healthInsuranceChild.comment = '';
        this.criticalDiseaseChild.comment = '';
        this.criticalDisease.comment = '';
        this.childPension.comment = '';
        this.deathSum.comment = '';
        this.invalideSum.comment = '';
        this.pensionContribution.comment = '';
        this.workAbilityPayment.comment = '';
        this.workAbility.comment = '';
        this.healthInsurances.forEach((healthInsurance) => {
            healthInsurance.comment = '';
        });
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)()
], Insurance.prototype, "supplierId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], Insurance.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], Insurance.prototype, "supplierPriceGroup", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Insurance.prototype, "supplierTemplateId", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], Insurance.prototype, "healthInsuranceIsMandatory", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "workAbility", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "workAbilityPayment", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "deathSum", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "pensionContribution", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "invalideSum", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "childPension", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "criticalDisease", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "criticalDiseaseChild", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "healthInsurance", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "healthInsurancePayment", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "healthInsurances", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "healthInsuranceChild", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "healthInsurancePartner", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "supplementarySalary", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "waitingPeriode", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "expirationDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Insurance.prototype, "drawingWindow", void 0);
exports.Insurance = Insurance;
