"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyPension = void 0;
const class_validator_1 = require("class-validator");
const pension_1 = require("../general/pension");
// * CompanyPension contains an array of groups and an array of employee pensions -
// which describes which groups including employees are covered by this pension.
//
// * This holds also the information about the employee's individually agreed pension
class CompanyPension {
    constructor(fields) {
        this.isMainAgreement = false;
        this.pension = new pension_1.Pension({});
        this.groups = [];
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'pension':
                    this.pension = new pension_1.Pension(value);
                    break;
                case 'groups':
                    this.groups = value;
                    break;
                case 'id':
                    this.id = value;
                    break;
                case 'isMainAgreement':
                    this.isMainAgreement = value;
                    break;
            }
        }
    }
    addGroup(groupId) {
        this.groups.push(groupId);
    }
    removeGroup(groupId) {
        const index = this.groups.indexOf(groupId);
        if (index > -1) {
            this.groups.splice(index, 1);
        }
    }
    toJSONObject() {
        return {
            pension: this.pension.toJSONObject(),
            groups: this.groups,
            id: this.id,
            isMainAgreement: this.isMainAgreement,
        };
    }
    toDatabase() {
        return {
            pension: this.pension.toDatabase(),
            groups: this.groups,
            isMainAgreement: this.isMainAgreement,
            id: this.id,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], CompanyPension.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)()
], CompanyPension.prototype, "isMainAgreement", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], CompanyPension.prototype, "pension", void 0);
__decorate([
    (0, class_validator_1.IsArray)()
], CompanyPension.prototype, "groups", void 0);
exports.CompanyPension = CompanyPension;
