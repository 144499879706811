"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
const class_validator_1 = require("class-validator");
const policy_1 = require("./sub-models/user/policy");
const claim_1 = require("./sub-models/role/claim");
class User {
    constructor(fields) {
        this.fullName = '';
        this.isActive = true;
        this.phone = '';
        this.phoneSecondary = '';
        this.policies = [];
        this.role = new claim_1.Claim({});
        this.createdBy = {};
        this.updatedBy = {};
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'createdAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'firstName':
                    // Some case firstname is having a empty space in the end
                    this[key] = value.trim();
                    break;
                case 'lastName':
                    // Some case lastname is having a empty space in the end
                    this[key] = value.trim();
                    break;
                case 'updatedAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'policies':
                    this[key] = value.map((el) => {
                        return new policy_1.Policy(el);
                    });
                    break;
                case 'role':
                    this[key] = new claim_1.Claim(value);
                    break;
                case 'cpr':
                    this[key] = value.replace('-', '');
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
        this.fullName = this.firstName + ' ' + this.lastName;
    }
    addPolicy({ policyId, companyId }) {
        this.policies.push(new policy_1.Policy({ policyId: policyId, companyId: companyId }));
    }
    toJSONObject() {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            fullName: this.firstName + ' ' + this.lastName,
            isActive: this.isActive,
            email: this.email,
            emailSecondary: this.emailSecondary,
            description: this.description,
            policies: this.policies.map((item) => {
                return item.toJSONObject();
            }),
            cpr: this.cpr,
            phone: this.phone,
            phoneSecondary: this.phoneSecondary,
            role: this.role.toJSONObject(),
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            createdBy: this.createdBy,
            updatedBy: this.updatedBy,
        };
    }
    toDatabase() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            fullName: this.firstName + ' ' + this.lastName,
            isActive: this.isActive,
            email: this.email,
            emailSecondary: this.emailSecondary,
            description: this.description,
            policies: this.policies.map((item) => {
                return item.toDatabase();
            }),
            cpr: this.cpr,
            phone: this.phone,
            phoneSecondary: this.phoneSecondary,
            role: this.role.toDatabase(),
            createdBy: this.createdBy,
            updatedBy: this.updatedBy,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], User.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], User.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], User.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], User.prototype, "fullName", void 0);
__decorate([
    (0, class_validator_1.IsString)({
        message: 'CPR is required',
    })
], User.prototype, "cpr", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], User.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], User.prototype, "emailSecondary", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)()
], User.prototype, "isActive", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], User.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.ValidateIf)((o) => o.phone !== ''),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Contains)('+')
], User.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], User.prototype, "phoneSecondary", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], User.prototype, "policies", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], User.prototype, "role", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], User.prototype, "createdAt", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], User.prototype, "updatedAt", void 0);
__decorate([
    (0, class_validator_1.IsOptional)()
], User.prototype, "createdBy", void 0);
__decorate([
    (0, class_validator_1.IsOptional)()
], User.prototype, "updatedBy", void 0);
exports.User = User;
