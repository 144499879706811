import axios from 'axios'
import config from '../../../../../conf.json'
import { superModel } from '../../../../models/superModel'

const employeeVuex = {
	namespaced: true,
	state: {
		employeePolicy: null,
		employeeLogs: [],
		company: null,
		companyAdvisors: [],
		user: null,
		isReady: false,
	},
	mutations: {
		setCompany(state, data) {
			state.company = data
		},
		setCompanyAdvisors(state, data) {
			state.companyAdvisors = data
		},
		setEmployeePolicy(state, data) {
			state.employeePolicy = data
		},
		setEmployeeLogs(state, data) {
			state.employeeLogs = data
		},
		setUser(state, data) {
			state.user = data
		},
		setIsReady(state, bool) {
			state.isReady = bool
		},
	},
	actions: {
		async reload({ commit, dispatch }, { companyId, employeeId }) {
			dispatch('clearState')
			var promises = []
			promises.push(dispatch('getCompany', companyId))
			promises.push(dispatch('getEmployeePolicy', employeeId))
			promises.push(dispatch('getUser', employeeId))
			promises.push(dispatch('getCompanyAdvisors', companyId))
			await Promise.all(promises)
			commit('setIsReady', true)
		},
		async clearState({ commit }) {
			commit('setIsReady', false)
			commit('setEmployeeLogs', [])
			commit('setEmployeePolicy', null)
			commit('setUser', null)
			commit('setCompany', null)
			commit('setCompanyAdvisors', [])
		},
		async getUser({ commit, rootGetters }, id) {
			const idToken = await rootGetters['auth/idToken']

			const options = {
				url: config.firebaseConfig.apiURL + '/user/' + id,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			const result = await axios(options).catch((error) => {
				throw error.response.data
			})
			const object = await superModel('user', result.data)
			commit('setUser', object)
		},
		async getCompanyAdvisors({ rootGetters, commit }, companyId) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/advisor',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				var user = await superModel('user', data)
				arr.push(user)
			}
			console.log('Getting company advisors')
			commit('setCompanyAdvisors', arr)
		},
		async getCompany({ commit, rootGetters }, id) {
			const idToken = await rootGetters['auth/idToken']

			const options = {
				url: config.firebaseConfig.apiURL + '/company/' + id,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			const result = await axios(options).catch((error) => {
				throw error.response.data
			})
			const company = await superModel('company', result.data)
			commit('setCompany', company)
		},
		async addEmployeePolicyForTheUser({ rootGetters, dispatch }, { companyId, employeeId }) {
			const idToken = await rootGetters['auth/idToken']

			const options = {
				url: config.firebaseConfig.apiURL + '/employeePolicy/' + employeeId + '/company/' + companyId,
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				throw error.response.data
			})
			return result
		},
		async getEmployeePolicy({ rootGetters, commit }, { employeeId, companyId, policyId }) {
			const idToken = await rootGetters['auth/idToken']

			let options = {}

			// PoliyId is optional and if it is provided, we will get the policy by policyId
			// Otherwise, we will get the ACTIVE policy by employeeId and companyId

			if (policyId) {
				options = {
					url: config.firebaseConfig.apiURL + '/employeePolicy/' + policyId,
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
			} else {
				options = {
					url: config.firebaseConfig.apiURL + '/employeePolicy/' + employeeId + '/company/' + companyId,
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
			}

			const result = await axios(options).catch((error) => {
				console.log(error)
				throw error.response.data
			})
			const employeePolicy = await superModel('employeePolicy', result.data)
			commit('setEmployeePolicy', employeePolicy)
		},
		async updateEmployeePolicy({ rootGetters, dispatch }, { id, companyId, employeeId, data }) {
			const idToken = await rootGetters['auth/idToken']
			const options = {
				url: config.firebaseConfig.apiURL + '/employeePolicy/' + id,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			const result = await axios(options).catch((error) => {
				console.log(error)
				throw error.response.data
			})
			var policyId = id
			await dispatch('employeeVuex/getEmployeePolicy', { companyId, employeeId, policyId }, { root: true })
			return result
		},
		async getEmployeeLogs({ commit, rootGetters }, id) {
			const idToken = await rootGetters['auth/idToken']

			const options = {
				url: config.firebaseConfig.apiURL + '/employeePolicy/' + id + '/logs',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			const result = await axios(options).catch((error) => {
				throw error.response.data
			})
			const arr = []
			for (const data of result.data) {
				const log = await superModel('log', data)
				arr.push(log)
			}
			commit('setEmployeeLogs', arr)
		},
		async deleteEmployeePolicy({ rootGetters, dispatch }, { policyId }) {
			const idToken = await rootGetters['auth/idToken']
			const options = {
				url: config.firebaseConfig.apiURL + '/employeePolicy/' + policyId,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data
			})
			return result
		},
		async resignEmployee({ rootGetters, dispatch }, { companyId, employeeId, policyId }) {
			const idToken = await rootGetters['auth/idToken']

			const options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/employee/' + employeeId + '/policyId/' + policyId,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data
			})
			return result
		},
	},
	getters: {
		employeePolicy(state) {
			return state.employeePolicy
		},
		employeeLogs(state) {
			return state.employeeLogs
		},
		employeePolicyId(state) {
			return state.employeePolicy.id
		},
		user(state) {
			return state.user
		},
		company(state) {
			return state.company
		},
		companyAdvisors(state) {
			return state.companyAdvisors
		},
		isReady(state) {
			return state.isReady
		},
	},
}

export default employeeVuex
