import axios from 'axios'
import config from '../../../../../conf.json'
import { superModel } from '../../../../models/superModel'

const userVuex = {
	namespaced: true,
	state: {
		user: null,
		userAuth: null,
		userEmployeePolicies: [],
		userCompanies: [],
		userLogs: [],
		apikey: null,
		isReady: false,
	},
	actions: {
		async reload({ commit, dispatch }, id) {
			dispatch('clearState')
			dispatch('getUser', id)
			dispatch('getUserAuth', id)
			dispatch('getUserEmployeePolicies', id)
			commit('setIsReady', true)
		},
		async clearState({ commit }) {
			commit('setUserAuth', null)
			commit('setUser', null)
			commit('setApikey', null)
			commit('setUserLogs', [])
			commit('setUserEmployeePolicies', [])
			commit('setIsReady', false)
			console.log('User vuex state resetted')
		},
		async createAuth({ commit, rootGetters }, { id, email, firstName, lastName }) {
			let idToken = await rootGetters['auth/idToken']
			var payload = {
				email: email,
				firstName: firstName,
				lastName: lastName,
			}
			var options = {
				url: config.firebaseConfig.apiURL + '/auth/' + id,
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: payload,
			}
			try {
				var result = await axios(options)
				await this.dispatch('userVuex/reload', id)
				return result
			} catch (err) {
				console.log(err.response.data)
				throw err.response.data.code + ': ' + err.response.data.message
			}
		},
		async getUserAuth({ commit, rootGetters }, id) {
			let idToken = await rootGetters['auth/idToken']
			var options = {
				url: config.firebaseConfig.apiURL + '/auth/' + id,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var object = result.data
			commit('setUserAuth', object)
		},
		async getUser({ commit, rootGetters }, id) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/user/' + id,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var object = await superModel('user', result.data)
			commit('setUser', object)
		},
		async getUserEmployeePolicies({ commit, rootGetters }, userId) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/user/' + userId + '/employeePolicy',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			const arr = []

			for (const data of result.data) {
				const employeePolicy = await superModel('employeePolicy', data)
				arr.push(employeePolicy)
			}

			let companyPromises = []
			var ids = arr.map((item) => item.companyId)

			for (const id of ids) {
				const options = {
					url: config.firebaseConfig.apiURL + '/company/' + id,
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
				companyPromises.push(axios(options))
			}
			const results = await Promise.all(companyPromises)
			// Set companies
			const companies = []
			for (const result of results) {
				const company = await superModel('company', result.data)
				companies.push(company)
			}

			// Filter away duplicate companies
			const uniqueCompanies = companies.filter((company, index, self) => {
				return index === self.findIndex((t) => t.id === company.id)
			})
			commit('setUserCompanies', uniqueCompanies)
			commit('setUserEmployeePolicies', arr)
		},

		async getApikey({ commit, rootGetters }, id) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/apikey/' + id,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var object = await superModel('apikey', result.data)
			commit('setApikey', object)
		},
		async registerApikey({ commit, rootGetters }, id) {
			let idToken = await rootGetters['auth/idToken']
			var uuid = require('uuid')
			var apikey = uuid.v4().replaceAll('-', '')

			var data = {
				uid: id,
				apikey: apikey,
			}
			var options = {
				url: config.firebaseConfig.apiURL + '/apikey',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options)
			this.dispatch('userVuex/getApikey', id)
			return result
		},
		async updateUser({ commit, rootGetters }, { id, data }) {
			let idToken = await rootGetters['auth/idToken']
			var object = await superModel('user', data)
			var payload = object.toDatabase()

			var options = {
				url: config.firebaseConfig.apiURL + '/user/' + id,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: payload,
			}
			try {
				var result = await axios(options)
				this.dispatch('userVuex/reload', id)
				return result
			} catch (err) {
				console.log(err.response.data)
				throw err.response.data.code + ': ' + err.response.data.message
			}
		},

		async changeEmail({ rootGetters }, { id, newEmail }) {
			let idToken = await rootGetters['auth/idToken']
			var options = {
				url: config.firebaseConfig.apiURL + '/auth/' + id + '/email',
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: { email: newEmail },
			}
			try {
				var result = await axios(options)
				this.dispatch('userVuex/reload', id)
				return result
			} catch (err) {
				console.log(err.response.data)
				throw err.response.data.code + ': ' + err.response.data.message
			}
		},
		async changePassword({ rootGetters }, { id, newPassword }) {
			let idToken = await rootGetters['auth/idToken']
			var options = {
				url: config.firebaseConfig.apiURL + '/auth/' + id + '/password',
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: { password: newPassword },
			}
			try {
				var result = await axios(options)
				this.dispatch('userVuex/reload', id)
				return result
			} catch (err) {
				console.log(err.response.data)
				throw err.response.data.code + ': ' + err.response.data.message
			}
		},
		async deleteUser({ rootGetters, dispatch }, id) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/user/' + id,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('user/reload', null, { root: true })
		},
		async getUserLogs({ commit, rootGetters }, id) {
			const idToken = await rootGetters['auth/idToken']

			const options = {
				url: config.firebaseConfig.apiURL + '/user/' + id + '/logs',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			const result = await axios(options).catch((error) => {
				throw error.response.data
			})
			const arr = []

			for (const data of result.data) {
				const log = await superModel('log', data)
				arr.push(log)
			}
			commit('setUserLogs', arr)
		},
	},
	mutations: {
		setUserAuth(state, data) {
			state.userAuth = data
		},
		setUser(state, data) {
			state.user = data
		},
		setApikey(state, data) {
			state.apikey = data
		},
		setUserClaims(state, data) {
			state.userClaims = data
		},
		setUserLogs(state, data) {
			state.userLogs = data
		},
		setIsReady(state, bool) {
			state.isReady = bool
		},
		setUserCompanies(state, data) {
			state.userCompanies = data
		},
		setUserInactiveCompanies(state, data) {
			state.userInactiveCompanies = data
		},
		setUserEmployeePolicies(state, data) {
			state.userEmployeePolicies = data
		},
	},
	getters: {
		userEmployeePolicies(state) {
			return state.userEmployeePolicies
		},

		userInactiveCompanies(state) {
			return state.userInactiveCompanies
		},
		userCompanies(state) {
			return state.userCompanies
		},
		userAuth(state) {
			return state.userAuth
		},
		apikeyReady(state) {
			return state.apikey != null
		},
		user(state) {
			return state.user
		},
		apikey(state) {
			return state.apikey
		},
		userClaims(state) {
			return state.userClaims
		},
		fullName(state) {
			return state.user?.firstName + ' ' + state.user?.lastName ?? ''
		},
		userLogs(state) {
			return state.userLogs
		},
		isReady(state) {
			return state.isReady
		},
	},
}

export default userVuex
