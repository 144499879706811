"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeInsurance = void 0;
const class_validator_1 = require("class-validator");
const standardPolicy_1 = require("../general/standardPolicy");
class EmployeeInsurance {
    constructor(fields) {
        this.insuranceId = null;
        this.workAbility = new standardPolicy_1.StandardPolicy({ title: 'Tab af erhvervsevne', type: 'percentage' });
        this.workAbilityPayment = new standardPolicy_1.StandardPolicy({ title: 'Udbetaling af forsikring ved tab af erhvervsevne' });
        this.deathSum = new standardPolicy_1.StandardPolicy({ title: 'Dødsfaldssum', type: 'percentage' });
        this.pensionContribution = new standardPolicy_1.StandardPolicy({ title: 'Præmiefritagelse for pensionsbidrag', type: 'percentage' });
        this.invalideSum = new standardPolicy_1.StandardPolicy({ title: 'Invalidesum', type: 'currency' });
        this.childPension = new standardPolicy_1.StandardPolicy({ title: 'Børnerente', type: 'percentage' });
        this.criticalDisease = new standardPolicy_1.StandardPolicy({ title: 'Visse kritiske sygdom', type: 'currency' });
        this.criticalDiseaseChild = new standardPolicy_1.StandardPolicy({ title: 'Visse kritiske sygdom børn', type: 'currency' });
        this.healthInsurance = new standardPolicy_1.StandardPolicy({ title: 'Sundhedsforsikring' });
        this.healthInsuranceChild = new standardPolicy_1.StandardPolicy({ title: 'Sundhedsforsikring til børn' });
        this.healthInsurancePartner = new standardPolicy_1.StandardPolicy({ title: 'Sundhedsforsikring til medforsikrede' });
        this.supplementarySalary = new standardPolicy_1.StandardPolicy({ title: 'Tillægsløn er dækningsgivende', value: false });
        this.startDate = new standardPolicy_1.StandardPolicy({ title: 'Ikrafttrædelsesdato' });
        this.waitingPeriode = new standardPolicy_1.StandardPolicy({ title: 'Karansperiode' });
        this.expirationDate = new standardPolicy_1.StandardPolicy({ title: 'Udløbsdato' });
        this.drawingWindow = new standardPolicy_1.StandardPolicy({ title: 'Tegningsvindue' });
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'workAbility':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'workAbilityPayment':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'pensionContribution':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'invalideSum':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'deathSum':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'childPension':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'criticalDisease':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'criticalDiseaseChild':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'healthInsurance':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'healthInsuranceChild':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'healthInsurancePartner':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'supplementarySalary':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'startDate':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'waitingPeriode':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'expirationDate':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'drawingWindow':
                    this[key] = new standardPolicy_1.StandardPolicy(value);
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
    toJSONObject() {
        return {
            insuranceId: this.insuranceId,
            workAbility: this.workAbility.toJSONObject(),
            workAbilityPayment: this.workAbilityPayment.toJSONObject(),
            deathSum: this.deathSum.toJSONObject(),
            pensionContribution: this.pensionContribution.toJSONObject(),
            invalideSum: this.invalideSum.toJSONObject(),
            childPension: this.childPension.toJSONObject(),
            criticalDisease: this.criticalDisease.toJSONObject(),
            criticalDiseaseChild: this.criticalDiseaseChild.toJSONObject(),
            healthInsurance: this.healthInsurance.toJSONObject(),
            healthInsuranceChild: this.healthInsuranceChild.toJSONObject(),
            healthInsurancePartner: this.healthInsurancePartner.toJSONObject(),
            supplementarySalary: this.supplementarySalary.toJSONObject(),
            startDate: this.startDate.toJSONObject(),
            waitingPeriode: this.waitingPeriode.toJSONObject(),
            expirationDate: this.expirationDate.toJSONObject(),
            drawingWindow: this.drawingWindow.toJSONObject(),
        };
    }
    toDatabase() {
        return {
            insuranceId: this.insuranceId,
            workAbility: this.workAbility.toDatabase(),
            workAbilityPayment: this.workAbilityPayment.toDatabase(),
            deathSum: this.deathSum.toDatabase(),
            pensionContribution: this.pensionContribution.toDatabase(),
            invalideSum: this.invalideSum.toDatabase(),
            childPension: this.childPension.toDatabase(),
            criticalDisease: this.criticalDisease.toDatabase(),
            criticalDiseaseChild: this.criticalDiseaseChild.toDatabase(),
            healthInsurance: this.healthInsurance.toDatabase(),
            healthInsuranceChild: this.healthInsuranceChild.toDatabase(),
            healthInsurancePartner: this.healthInsurancePartner.toDatabase(),
            supplementarySalary: this.supplementarySalary.toDatabase(),
            startDate: this.startDate.toDatabase(),
            waitingPeriode: this.waitingPeriode.toDatabase(),
            expirationDate: this.expirationDate.toDatabase(),
            drawingWindow: this.drawingWindow.toDatabase(),
        };
    }
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)()
], EmployeeInsurance.prototype, "insuranceId", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "workAbility", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "workAbilityPayment", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "deathSum", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "pensionContribution", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "invalideSum", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "childPension", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "criticalDisease", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "criticalDiseaseChild", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "healthInsurance", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "healthInsuranceChild", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "healthInsurancePartner", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "supplementarySalary", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "waitingPeriode", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "expirationDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeeInsurance.prototype, "drawingWindow", void 0);
exports.EmployeeInsurance = EmployeeInsurance;
