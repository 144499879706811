"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateCondition = void 0;
const class_validator_1 = require("class-validator");
const personInfo_1 = require("./personInfo");
class PrivateCondition {
    constructor(fields) {
        this.childrens = [];
        this.partners = [];
        this.assets = '';
        this.beneficiary = '';
        this.livingCondition = '';
        this.equity = '';
        this.riskChance = '';
        this.debt = '';
        this.maritalStatus = '';
        this.consent = '';
        this.powerOfAttorney = '';
        this.cooperation = '';
        this.politicalExPerson = false;
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'partners':
                    this[key] = value.map((el) => {
                        return new personInfo_1.PersonInfo(el);
                    });
                    break;
                case 'childrens':
                    this[key] = value.map((el) => {
                        return new personInfo_1.PersonInfo(el);
                    });
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            assets: this.assets,
            beneficiary: this.beneficiary,
            livingCondition: this.livingCondition,
            equity: this.equity,
            riskChance: this.riskChance,
            debt: this.debt,
            maritalStatus: this.maritalStatus,
            consent: this.consent,
            powerOfAttorney: this.powerOfAttorney,
            politicalExPerson: this.politicalExPerson,
            cooperation: this.cooperation,
            partners: this.partners.map((item) => {
                return item.toJSONObject();
            }),
            childrens: this.childrens.map((item) => {
                return item.toJSONObject();
            }),
        };
    }
    toDatabase() {
        return {
            assets: this.assets,
            beneficiary: this.beneficiary,
            livingCondition: this.livingCondition,
            equity: this.equity,
            riskChance: this.riskChance,
            debt: this.debt,
            maritalStatus: this.maritalStatus,
            consent: this.consent,
            powerOfAttorney: this.powerOfAttorney,
            politicalExPerson: this.politicalExPerson,
            cooperation: this.cooperation,
            partners: this.partners.map((item) => {
                return item.toDatabase();
            }),
            childrens: this.childrens.map((item) => {
                return item.toDatabase();
            }),
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsArray)()
], PrivateCondition.prototype, "childrens", void 0);
__decorate([
    (0, class_validator_1.IsArray)()
], PrivateCondition.prototype, "partners", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "assets", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "beneficiary", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "livingCondition", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "equity", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "riskChance", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "debt", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "maritalStatus", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "consent", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "powerOfAttorney", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], PrivateCondition.prototype, "cooperation", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)()
], PrivateCondition.prototype, "politicalExPerson", void 0);
exports.PrivateCondition = PrivateCondition;
