import i18n from '@/plugins/i18n'

const UserBase = () => import('@/modules/general/user/views/UserBase')
const UserList = () => import('@/modules/general/user/views/UserList')
const UserInfo = () => import('@/modules/general/user/views/Info')
const UserAccount = () => import('@/modules/general/user/views/Account')
const UserApi = () => import('@/modules/general/user/views/Api')
const UserSettings = () => import('@/modules/general/user/views/UserSettings')
const UserAgreements = () => import('@/modules/general/user/views/Agreements')
const InactiveAgreements = () => import('@/modules/general/user/views/InactiveAgreements')

const UserLogs = () => import('@/modules/general/user/views/UserLogs')

export default [
	{
		path: '/profile/:id/',
		name: 'User',
		component: UserBase,
		redirect: { name: 'Info' },
		meta: {
			requiresLoggedIn: true,
			title: i18n.t('browserTitle.profile'),
		},
		children: [
			{
				path: 'info',
				name: 'Info',
				component: UserInfo,
				meta: {
					title: i18n.t('browserTitle.user.info'),
				},
			},
			{
				path: 'account',
				name: 'Account',
				component: UserAccount,
				meta: {
					title: i18n.t('browserTitle.user.account'),
				},
			},
			{
				path: 'agreements',
				name: 'Agreements',
				component: UserAgreements,
				meta: {
					title: i18n.t('browserTitle.user.agreements'),
				},
			},
			{
				path: 'inactiveAgreements',
				name: 'InactiveAgreements',
				component: InactiveAgreements,
				meta: {
					title: "Inaktive aftaler",
				},
			},
			{
				path: 'api',
				name: 'Api',
				component: UserApi,
				meta: {
					title: i18n.t('browserTitle.user.api'),
				},
			},
			{
				path: 'settings',
				name: 'UserSettings',
				component: UserSettings,
				meta: {
					title: i18n.t('browserTitle.user.settings'),
				},
			},
			{
				path: 'logs',
				name: 'UserLogs',
				component: UserLogs,
				meta: {
					title: i18n.t('browserTitle.user.logs'),
				},
			},
		],
	},
	{
		path: '/user/list',
		name: 'UserList',
		component: UserList,
		meta: {
			requiresLoggedIn: true,
			title: i18n.t('browserTitle.users'),
		},
	},
]
