import axios from 'axios'
import config from '../../../conf.json'
import { superModel } from '../../models/superModel'

const user = {
	namespaced: true,
	state: {
		users: [],
		isReady: false,
	},
	actions: {
		async clearState({ commit, rootGetters }) {
			commit('setUsers', [])
			commit('setIsReady', false)
		},
		async reload({ commit, rootGetters }) {
			this.dispatch('user/getUsers')
		},
		async getUsers({ commit, rootGetters }) {
			if (!rootGetters['user/isReady']) {
				console.log('Loading users')
				let idToken = await rootGetters['auth/idToken']

				var options = {
					url: config.firebaseConfig.apiURL + '/user/',
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
				var result = await axios(options)

				var arr = []
				for (const data of result.data) {
					var person = await superModel('user', data)
					arr.push(person)
				}

				commit('setUsers', arr)
				commit('setIsReady', true)
			}
		},
	},
	mutations: {
		setUsers(state, data) {
			state.users = data
		},
		setIsReady(state, bool) {
			state.isReady = bool
		},
		clear(state) {
			state.users = []
		},
	},
	getters: {
		isSet(state) {
			return state.users.length > 0
		},
		isReady(state) {
			return state.isReady
		},
		users(state) {
			return state.users
		},
		advisors(state) {
			return state.users.filter((user) => {
				return user.role.isAdmin || user.role.isAdvisor
			})
		},
	},
}

export default user
