import Vue from "vue"
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
  
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      const lang = key.split("./")[1].split("/")[0]
      const formattedLocale = key.split("/")[2].split(".json")[0]
      if (matched && matched.length > 1) {
        if (messages.hasOwnProperty(lang)) {
          messages[lang][formattedLocale] = locales(key)
        }
        else {
          messages[lang] = {}
          messages[lang][formattedLocale] = locales(key)
        }
      }
    })
    return messages
  }
  
  function getLocale() {
    return "da"
  }
  
  
  export default new VueI18n({
    locale: getLocale(),
    messages: loadLocaleMessages()
  })
  
  
  