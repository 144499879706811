import Vue from "vue"
import BootstrapVue from 'bootstrap-vue'

// Styles
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'babel-polyfill'
import 'core-js/stable'

// Invoke the plugin
Vue.use(BootstrapVue)