"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Absence = void 0;
const class_validator_1 = require("class-validator");
class Absence {
    constructor(fields) {
        this.absenceStartDate = null;
        this.absenceEndDate = null;
        this.comment = '';
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'absenceStartDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'absenceEndDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            absenceStartDate: this.absenceStartDate,
            absenceEndDate: this.absenceEndDate,
            comment: this.comment,
            pensionUnderAbsence: this.pensionUnderAbsence,
            salaryUnderAbsence: this.salaryUnderAbsence,
        };
    }
    toDatabase() {
        return {
            absenceStartDate: this.absenceStartDate,
            absenceEndDate: this.absenceEndDate,
            comment: this.comment,
            pensionUnderAbsence: this.pensionUnderAbsence,
            salaryUnderAbsence: this.salaryUnderAbsence,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], Absence.prototype, "absenceStartDate", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], Absence.prototype, "absenceEndDate", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], Absence.prototype, "salaryUnderAbsence", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], Absence.prototype, "pensionUnderAbsence", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Absence.prototype, "comment", void 0);
exports.Absence = Absence;
