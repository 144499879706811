import Vue from "vue"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCropAlt, faAddressCard, faBuilding, faServer, faStar, faUser, faSpinner, faHome, faBiking, faMapMarkerAlt, faMapMarkedAlt, faUserShield, faDoorOpen, faUsers, faQuestion, faChevronUp, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faListAlt, faQuestionCircle, faMap } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'


// Add the specific imported icons

library.add(far)
library.add(fas)
library.add(faStar)
library.add(faUser)
library.add(faSpinner)
library.add(faHome)
library.add(faBiking)
library.add(faMapMarkerAlt)
library.add(faMapMarkedAlt)
library.add(faUserShield)
library.add(faDoorOpen)
library.add(faQuestionCircle)
library.add(faUsers)
library.add(faQuestion)
library.add(faChevronUp)
library.add(faChevronDown)
library.add(faCheck)
library.add(faMap)
library.add(faServer)
library.add(faBuilding)
library.add(faAddressCard)
library.add(faCropAlt)
library.add(faListAlt)

// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon)
