<template>
    <section>
      <div class="row">
        <div class="col-2" :class="success ? 'success' : 'error'">
          <template v-if="success">
            <font-awesome-icon :icon="['fa', 'check']" class="icon" />
          </template>
          <template v-else>
            <font-awesome-icon :icon="['fa', 'exclamation']" class="icon" />
          </template>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-12 title mt-2">
              <h5><b>{{ title }}</b></h5>
              <p>{{ message }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: "BaseToast",
  props: {
    title: String,
    message: String,
    success: Boolean
  }
}
</script>