import Vue from 'vue'

import browserDetect from 'vue-browser-detect-plugin'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTelInput from 'vue-tel-input'
import VueHead from 'vue-head'
import NProgress from 'vue-nprogress'
import Toast from 'vue-toastification'
import toastMixin from '@/mixins/toastMixin.js'
import VueCookies from 'vue-cookies'

// Styles
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-tel-input/dist/vue-tel-input.css'
import 'vue-toastification/dist/index.css'

Vue.use(VueSweetalert2)
Vue.use(browserDetect)
Vue.use(VueTelInput)
Vue.use(VueHead)
Vue.use(NProgress)
Vue.use(Toast)
Vue.use(toastMixin)
Vue.use(VueCookies, { expires: '3y' })
