import i18n from '@/plugins/i18n';

// Suppliers / Leverandører
const SupplierList = () => import('@/modules/general/supplier/views/SupplierList')
const SupplierAdd = () => import('@/modules/general/supplier/views/SupplierAdd');
const SupplierInfo = () => import('@/modules/general/supplier/views/SupplierInfo')
const SupplierContacts = () => import('@/modules/general/supplier/views/SupplierContacts')
const SupplierPriceGroup = () => import('@/modules/general/supplier/views/SupplierPriceGroup')
const SupplierPension = () => import('@/modules/general/supplier/views/SupplierPension')
const SupplierInsurance = () => import('@/modules/general/supplier/views/SupplierInsurance')
const SupplierLogs = () => import('@/modules/general/supplier/views/SupplierLogs')
const SupplierSettings = () => import('@/modules/general/supplier/views/SupplierSettings')

const BaseSupplier = () => import('@/modules/general/supplier/views/BaseSupplier')

export default [
    {
        path: '/supplier/list',
        name: 'Suppliers',
        component: SupplierList,
        meta: {
            requiresLoggedIn: true,
            title: i18n.t('browserTitle.suppliers.list')
        }
    },
    {
        path: '/supplier/add',
        name: 'SupplierAdd',
        component: SupplierAdd,
        meta: {
            requiresLoggedIn: true,
            title: i18n.t('browserTitle.suppliers.add')
        }
    },

    {
        path: '/supplier/:supplierId/',
        name: 'BaseSupplier',
        component: BaseSupplier,
        children: [
            {
                path: 'info',
                name: 'SupplierInfo',
                component: SupplierInfo,
                meta: {
                    requiresLoggedIn: true,
                    title: i18n.t('browserTitle.supplier.info') + ' | ' + i18n.t('browserTitle.suppliers.update')
                }
            },
            {
                path: 'contacts',
                name: 'SupplierContacts',
                component: SupplierContacts,
                meta: {
                    requiresLoggedIn: true,
                    title: i18n.t('browserTitle.supplier.contact') + ' | ' + i18n.t('browserTitle.suppliers.update')
                }
            },
            {
                path: 'priceGroup',
                name: 'SupplierPriceGroup',
                component: SupplierPriceGroup,
                meta: {
                    requiresLoggedIn: true,
                    title: i18n.t('browserTitle.supplier.priceGroup') + ' | ' + i18n.t('browserTitle.suppliers.update')
                }
            },
            {
                path: 'pension',
                name: 'SupplierPension',
                component: SupplierPension,
                meta: {
                    requiresLoggedIn: true,
                    title: i18n.t('browserTitle.supplier.pension') + ' | ' + i18n.t('browserTitle.suppliers.update')
                }
            },
            {
                path: 'insurance',
                name: 'SupplierInsurance',
                component: SupplierInsurance,
                meta: {
                    requiresLoggedIn: true,
                    title: i18n.t('browserTitle.supplier.insurance') + ' | ' + i18n.t('browserTitle.suppliers.update')
                }
            },
            {
                path: 'logs',
                name: 'SupplierLogs',
                component: SupplierLogs,
                meta: {
                    requiresLoggedIn: true,
                    title: i18n.t('browserTitle.supplier.logs') + ' | ' + i18n.t('browserTitle.suppliers.update')
                }
            },
            {
                path: 'settings',
                name: 'SupplierSettings',
                component: SupplierSettings,
                meta: {
                    requiresLoggedIn: true,
                    title: i18n.t('browserTitle.supplier.settings') + ' | ' + i18n.t('browserTitle.suppliers.update')
                }
            }
        ]
    }
]