import {version} from '../../../package.json';

const app = {
    namespaced: true,
    state: {
        appVersion: version,
    },
    mutations: {
    },
    actions: {

    },
    getters: {
        appVersion(state) {
            console.log(version)
            return state.appVersion
        },
    }
}

export default app