"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeCondition = void 0;
const class_validator_1 = require("class-validator");
class EmployeeCondition {
    constructor(fields) {
        this.position = '';
        this.employmentDate = null;
        this.salaryVisible = true; // Option to hide visiblity of salary for companyAdmin
        this.resignationDate = null;
        this.resignationComment = '';
        this.resignationLastUpdated = null;
        this.employeeNumber = '';
        this.comment = '';
        this.pensionStartDate = null;
        this.healthInsuranceStartDate = null;
        this.supplementarySalary = 0;
        this.salaryAdjustmentDate = null;
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'employmentDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'resignationDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'resignationLastUpdated':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'pensionStartDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'healthInsuranceStartDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'salaryAdjustmentDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            position: this.position,
            resignationComment: this.resignationComment,
            group: this.group,
            employmentDate: this.employmentDate,
            resignationDate: this.resignationDate,
            employeeNumber: this.employeeNumber,
            comment: this.comment,
            pensionStartDate: this.pensionStartDate,
            healthInsuranceStartDate: this.healthInsuranceStartDate,
            salary: this.salary,
            supplementarySalary: this.supplementarySalary,
            coverSalary: this.coverSalary,
            salaryAdjustmentDate: this.salaryAdjustmentDate,
            salaryVisible: this.salaryVisible,
            resignationLastUpdated: this.resignationLastUpdated,
        };
    }
    toDatabase() {
        return {
            position: this.position,
            resignationComment: this.resignationComment,
            group: this.group,
            employmentDate: this.employmentDate,
            resignationDate: this.resignationDate,
            employeeNumber: this.employeeNumber,
            comment: this.comment,
            pensionStartDate: this.pensionStartDate,
            healthInsuranceStartDate: this.healthInsuranceStartDate,
            salary: this.salary,
            supplementarySalary: this.supplementarySalary,
            coverSalary: this.coverSalary,
            salaryAdjustmentDate: this.salaryAdjustmentDate,
            salaryVisible: this.salaryVisible,
            resignationLastUpdated: this.resignationLastUpdated,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "position", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "employmentDate", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)()
], EmployeeCondition.prototype, "salaryVisible", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "group", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "resignationDate", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "resignationComment", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "resignationLastUpdated", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "employeeNumber", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "comment", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "pensionStartDate", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "healthInsuranceStartDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)()
], EmployeeCondition.prototype, "salary", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "supplementarySalary", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "coverSalary", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], EmployeeCondition.prototype, "salaryAdjustmentDate", void 0);
exports.EmployeeCondition = EmployeeCondition;
