import i18n from '@/plugins/i18n'

// Company
const ResignedCompanyList = () => import(/* webpackChunkName: 'CompanyList' */ '@/modules/general/resignedCompany/views/ResignedCompanyList')

export default [
	{
		path: '/resignedCompany',
		name: 'ResignedCompanies',
		component: ResignedCompanyList,
		meta: {
			resource: 'Companies',
			action: 'read',
			requiresLoggedIn: true,
			title: i18n.t('browserTitle.companies.list'),
		},
	},
]
