import axios from 'axios'
import config from '../../../conf.json'
import { superModel } from '../../models/superModel'

const supplier = {
	namespaced: true,
	state: {
		suppliers: [],
		isReady: false,
	},
	mutations: {
		setSuppliers(state, data) {
			state.suppliers = data
		},
		setIsReady(state, bool) {
			state.isReady = bool
		},
	},
	actions: {
		clearState({ commit }) {
			commit('setSuppliers', [])
			commit('setIsReady', false)
		},
		async reload({ commit, rootGetters }) {
			commit('setIsReady', false)
			this.dispatch('supplier/getSuppliers')
		},
		async getSuppliers({ commit, rootGetters }) {
			if (!rootGetters['supplier/isReady']) {
				console.log('loading suppliers')
				let idToken = await rootGetters['auth/idToken']
				var options = {
					url: config.firebaseConfig.apiURL + '/supplier',
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + idToken,
					},
				}
				var result = await axios(options)
				var arr = []
				for (const data of result.data) {
					var supplier = await superModel('supplier', data)
					arr.push(supplier)
				}
				commit('setSuppliers', arr.sort((a, b) => a.name.localeCompare(b.name)))
				commit('setIsReady', true)
			}
		},
	},
	getters: {
		isReady(state) {
			return state.isReady
		},
		suppliers(state) {
			return state.suppliers
		},
	},
}

export default supplier
