"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupTemplate = void 0;
const class_validator_1 = require("class-validator");
const insurance_1 = require("../general/insurance");
const pension_1 = require("../general/pension");
class GroupTemplate {
    constructor(fields) {
        this.name = '';
        this.pension = new pension_1.Pension({});
        this.insurance = new insurance_1.Insurance({});
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'pension':
                    this[key] = new pension_1.Pension(value);
                    break;
                case 'insurance':
                    this[key] = new insurance_1.Insurance(value);
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            name: this.name,
            pension: this.pension.toJSONObject(),
            insurance: this.insurance.toJSONObject(),
            id: this.id,
        };
    }
    toDatabase() {
        return {
            name: this.name,
            pension: this.pension.toDatabase(),
            insurance: this.insurance.toDatabase(),
            id: this.id,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)()
], GroupTemplate.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], GroupTemplate.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], GroupTemplate.prototype, "pension", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], GroupTemplate.prototype, "insurance", void 0);
exports.GroupTemplate = GroupTemplate;
