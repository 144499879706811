import axios from 'axios'
import config from '../../../../../conf.json'
import { superModel } from '../../../../models/superModel'

const resignedCompanyVuex = {
	namespaced: true,
	state: {
		company: {},
		employeePolices: [],
		isReady: false,
		employeePolicyIsReady: false,
	},
	mutations: {
	
		setEmployeePolicies(state, arr) {
			state.employeePolices = arr
		},
		setReady(state, bool) {
			state.isReady = bool
		},
		
	},
	actions: {
		async clearState({ commit }) {
			commit('setCompany', [])
			commit('setEmployeePolicies', [])
			commit('setReady', false)
			commit('setEmployeesIsReady', false)
			commit('setEmployeePolicyIsReady', false)
			console.log('Company cleared')
		},
		async getResignedEmployeePolicies({ rootGetters, commit }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/employeePolicy/resigned/all',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				var employeePolicy = await superModel('employeePolicy', data)
				arr.push(employeePolicy)
			}
			commit('setEmployeePolicies', arr)
			commit('setReady', true)
		},
	},
	getters: {
		company(state) {
			return state.company
		},

		employeePolicies(state) {
			return state.employeePolices
		},

		isReady(state) {
			return state.isReady
		},
	},
}

export default resignedCompanyVuex
