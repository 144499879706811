"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeePension = void 0;
const class_validator_1 = require("class-validator");
const standardPolicy_1 = require("../general/standardPolicy");
class EmployeePension {
    constructor(fields) {
        this.pensionId = null;
        this.fromDate = null;
        this.voluntaryCompanyContribution = new standardPolicy_1.StandardPolicy({
            title: 'Fri firmaandel',
            type: 'percentage',
        });
        this.voluntaryEmployeeContribution = new standardPolicy_1.StandardPolicy({
            title: 'Fri medarbejderandel',
            type: 'percentage',
        });
        this.companyDeposit = new standardPolicy_1.StandardPolicy({
            title: 'Firma indbetaling',
            type: 'currency',
        }); // Monthly
        this.employeeDeposit = new standardPolicy_1.StandardPolicy({
            title: 'Egen indbetaling',
            type: 'currency',
        }); // Monthly
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'fromDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'voluntaryCompanyContribution':
                    this.voluntaryCompanyContribution = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'voluntaryEmployeeContribution':
                    this.voluntaryEmployeeContribution = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'employeeDeposit':
                    this.employeeDeposit = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'companyDeposit':
                    this.companyDeposit = new standardPolicy_1.StandardPolicy(value);
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            voluntaryCompanyContribution: this.voluntaryCompanyContribution.toJSONObject(),
            voluntaryEmployeeContribution: this.voluntaryEmployeeContribution.toJSONObject(),
            employeeDeposit: this.employeeDeposit.toJSONObject(),
            companyDeposit: this.companyDeposit.toJSONObject(),
            fromDate: this.fromDate,
            pensionId: this.pensionId,
        };
    }
    toDatabase() {
        return {
            voluntaryCompanyContribution: this.voluntaryCompanyContribution.toDatabase(),
            voluntaryEmployeeContribution: this.voluntaryEmployeeContribution.toDatabase(),
            employeeDeposit: this.employeeDeposit.toDatabase(),
            companyDeposit: this.companyDeposit.toDatabase(),
            fromDate: this.fromDate,
            pensionId: this.pensionId,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)()
], EmployeePension.prototype, "pensionId", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], EmployeePension.prototype, "fromDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeePension.prototype, "voluntaryCompanyContribution", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeePension.prototype, "voluntaryEmployeeContribution", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeePension.prototype, "companyDeposit", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeePension.prototype, "employeeDeposit", void 0);
exports.EmployeePension = EmployeePension;
