"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pension = void 0;
const class_validator_1 = require("class-validator");
const standardPolicy_1 = require("./standardPolicy");
class Pension {
    constructor(fields) {
        this.supplierId = '';
        this.name = '';
        this.supplierPriceGroup = '';
        this.supplierTemplateId = '';
        this.pensionIsMandatory = false;
        this.mandatoryCompanyShare = new standardPolicy_1.StandardPolicy({ title: 'Obligatorisk firmaandel', type: 'percentage' });
        this.mandatoryEmployeeShare = new standardPolicy_1.StandardPolicy({ title: 'Obligatorisk medarbejderandel', type: 'percentage' });
        this.isBeneficial = new standardPolicy_1.StandardPolicy({ title: 'Tillægsløn er pensionsgivende', value: false });
        this.startDate = new standardPolicy_1.StandardPolicy({ title: 'Ikrafttrædelse dato' });
        this.waitingPeriode = new standardPolicy_1.StandardPolicy({ title: 'Karansperiode' });
        this.expirationDate = new standardPolicy_1.StandardPolicy({ title: 'Udløbsdato' });
        this.drawingWindow = new standardPolicy_1.StandardPolicy({ title: 'Tegningsvindue' });
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'mandatoryCompanyShare':
                    this.mandatoryCompanyShare = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'mandatoryEmployeeShare':
                    this.mandatoryEmployeeShare = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'isBeneficial':
                    this.isBeneficial = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'startDate':
                    this.startDate = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'waitingPeriode':
                    this.waitingPeriode = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'expirationDate':
                    this.expirationDate = new standardPolicy_1.StandardPolicy(value);
                    break;
                case 'drawingWindow':
                    this.drawingWindow = new standardPolicy_1.StandardPolicy(value);
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    clearComments() {
        this.waitingPeriode.comment = '';
        this.drawingWindow.comment = '';
        this.expirationDate.comment = '';
        this.startDate.comment = '';
        this.mandatoryCompanyShare.comment = '';
        this.mandatoryEmployeeShare.comment = '';
        this.isBeneficial.comment = '';
    }
    toJSONObject() {
        return {
            supplierId: this.supplierId,
            name: this.name,
            supplierPriceGroup: this.supplierPriceGroup,
            supplierTemplateId: this.supplierTemplateId,
            mandatoryCompanyShare: this.mandatoryCompanyShare.toJSONObject(),
            mandatoryEmployeeShare: this.mandatoryEmployeeShare.toJSONObject(),
            isBeneficial: this.isBeneficial.toJSONObject(),
            startDate: this.startDate.toJSONObject(),
            waitingPeriode: this.waitingPeriode.toJSONObject(),
            expirationDate: this.expirationDate.toJSONObject(),
            drawingWindow: this.drawingWindow.toJSONObject(),
            pensionIsMandatory: this.pensionIsMandatory,
        };
    }
    toDatabase() {
        return {
            supplierId: this.supplierId,
            name: this.name,
            supplierPriceGroup: this.supplierPriceGroup,
            supplierTemplateId: this.supplierTemplateId,
            mandatoryCompanyShare: this.mandatoryCompanyShare.toDatabase(),
            mandatoryEmployeeShare: this.mandatoryEmployeeShare.toDatabase(),
            isBeneficial: this.isBeneficial.toDatabase(),
            startDate: this.startDate.toDatabase(),
            waitingPeriode: this.waitingPeriode.toDatabase(),
            expirationDate: this.expirationDate.toDatabase(),
            drawingWindow: this.drawingWindow.toDatabase(),
            pensionIsMandatory: this.pensionIsMandatory,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)()
], Pension.prototype, "supplierId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], Pension.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Pension.prototype, "supplierPriceGroup", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Pension.prototype, "supplierTemplateId", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], Pension.prototype, "pensionIsMandatory", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Pension.prototype, "mandatoryCompanyShare", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Pension.prototype, "mandatoryEmployeeShare", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Pension.prototype, "isBeneficial", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Pension.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Pension.prototype, "waitingPeriode", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Pension.prototype, "expirationDate", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Pension.prototype, "drawingWindow", void 0);
exports.Pension = Pension;
