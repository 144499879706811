"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardPolicy = void 0;
const class_validator_1 = require("class-validator");
class StandardPolicy {
    constructor(fields) {
        this.comment = '';
        this.taxCode = '';
        this.title = '';
        this.type = '';
        this.insuranceType = '';
        this.isSalaryScale = false; // Lønskala ifm TAE
        this.sameAsMandatoryContribution = false; // Obligatorisk bidrag ifm Præmiefritagelse
        this.date = null;
        this.value = null;
        this.ageGroup = '';
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'date':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            comment: this.comment,
            taxCode: this.taxCode,
            title: this.title,
            coverageSpanMin: this.coverageSpanMin,
            coverageSpanMax: this.coverageSpanMax,
            type: this.type,
            date: this.date,
            value: this.value,
            insuranceType: this.insuranceType,
            isSalaryScale: this.isSalaryScale,
            sameAsMandatoryContribution: this.sameAsMandatoryContribution,
            ageGroup: this.ageGroup,
        };
    }
    toDatabase() {
        return {
            comment: this.comment,
            taxCode: this.taxCode,
            title: this.title,
            coverageSpanMin: this.coverageSpanMin,
            coverageSpanMax: this.coverageSpanMax,
            type: this.type,
            date: this.date,
            value: this.value,
            insuranceType: this.insuranceType,
            isSalaryScale: this.isSalaryScale,
            sameAsMandatoryContribution: this.sameAsMandatoryContribution,
            ageGroup: this.ageGroup,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "comment", void 0);
__decorate([
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "taxCode", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "title", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "coverageSpanMin", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "coverageSpanMax", void 0);
__decorate([
    (0, class_validator_1.IsIn)(['currency', 'percentage', '']),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsIn)(['standard', 'expanded', '']),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "insuranceType", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "isSalaryScale", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "sameAsMandatoryContribution", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "date", void 0);
__decorate([
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "value", void 0);
__decorate([
    (0, class_validator_1.IsOptional)()
], StandardPolicy.prototype, "ageGroup", void 0);
exports.StandardPolicy = StandardPolicy;
