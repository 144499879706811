// Initialize firebase
import firebaseApp from '@/plugins/firebase.js' // Init firebaseApp
import { getAuth, onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth'

// Core imports
import Vue from 'vue'
import App from './App'

// Router
import router from './router'

// Vuex store
import store from './vuex/store'

// Plugins
import i18n from '@/plugins/i18n'
import '@/plugins/bootstrapVue.js'
import '@/plugins/fontAwesome.js'
import '@/plugins/libs.js'
import '@/plugins/localComponents.js'
import '@/plugins/papaParse.js'
import '@/plugins/casl'
import '@/plugins/veeValidate'

import NProgress from 'vue-nprogress'
const nprogress = new NProgress()

let app
const auth = getAuth()

onAuthStateChanged(auth, async (user) => {
	console.log('session changed')
	if (!user) {
		console.log('logging out')
		store.dispatch('auth/signOutAction')
	} else {
		const remaining = localStorage.getItem('lastActiveTime')
		var now = new Date().getTime()
		var diff = now - remaining
		// if(!remaining){
		// 	store.dispatch('auth/signOutAction')
		// }
		// else if (remaining && diff > 7200000) {
		// 	// 2 hour in miliseconds
		// 	console.log('Logging out')
		// 	store.dispatch('auth/signOutAction')
		// } else {
		await store.dispatch('auth/setUserAction', user)
		// Loading data according to role
		if (store.getters['auth/isEmployee'] && !store.getters['auth/isCompanyAdmin']) {
			store.dispatch('supplier/getSuppliers')
			var companyId = store.getters['auth/employeeCompanies'][0]
			var employeeId = store.getters['auth/uid']
			if (router.currentRoute?.query?.redirect ?? false) {
				router.push(router.currentRoute.query.redirect)
			} else {
				router.push({ name: 'CompanyEmployeeInfo', params: { companyId: companyId, employeeId: employeeId } })
			}
		} else {
			store.dispatch('supplier/getSuppliers')
			store.dispatch('user/getUsers')
			store.dispatch('company/getCompanies')
			if (router.currentRoute?.query?.redirect ?? false) {
				router.push(router.currentRoute.query.redirect)
			} else {
				router.push({ name: 'Companies' })
			}
		}
		// }
	}
})

app = new Vue({
	el: '#app',
	store,
	router,
	nprogress,
	template: '<App/>',
	components: {
		App,
	},
	i18n,
	render: (h) => h(App),
})
