"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Kyc = void 0;
const class_validator_1 = require("class-validator");
class Kyc {
    constructor(fields) {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.secondaryEmail = '';
        this.clientType = '';
        this.kycPurpose = '';
        this.cpr = '';
        this.kycSigned = '';
        this.kycDate = null;
        this.evaluationInterval = '';
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'kycDate':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            cpr: this.cpr,
            kycSigned: this.kycSigned,
            kycDate: this.kycDate,
            evaluationInterval: this.evaluationInterval,
            secondaryEmail: this.secondaryEmail,
            clientType: this.clientType,
            kycPurpose: this.kycPurpose,
        };
    }
    toDatabase() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            cpr: this.cpr,
            kycSigned: this.kycSigned,
            kycDate: this.kycDate,
            evaluationInterval: this.evaluationInterval,
            secondaryEmail: this.secondaryEmail,
            clientType: this.clientType,
            kycPurpose: this.kycPurpose,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "secondaryEmail", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "clientType", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "kycPurpose", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "cpr", void 0);
__decorate([
    (0, class_validator_1.IsOptional)()
], Kyc.prototype, "kycSigned", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], Kyc.prototype, "kycDate", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Kyc.prototype, "evaluationInterval", void 0);
exports.Kyc = Kyc;
