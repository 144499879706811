"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Company
const company_1 = require("./company");
const companyInfo_1 = require("./sub-models/company/companyInfo");
const group_1 = require("./sub-models/company/group");
const insurance_1 = require("./sub-models/general/insurance");
const pension_1 = require("./sub-models/general/pension");
const companyPension_1 = require("./sub-models/company/companyPension");
const companyInsurance_1 = require("./sub-models/company/companyInsurance");
// Employee
const employeePolicy_1 = require("./employeePolicy");
const privateCondition_1 = require("./sub-models/employeePolicy/privateCondition");
const individualCondition_1 = require("./sub-models/employeePolicy/individualCondition");
const employeeCondition_1 = require("./sub-models/employeePolicy/employeeCondition");
const personInfo_1 = require("./sub-models/employeePolicy/personInfo");
const employeeInsurance_1 = require("./sub-models/employeePolicy/employeeInsurance");
const employeePension_1 = require("./sub-models/employeePolicy/employeePension");
// Other
const user_1 = require("./user");
const supplier_1 = require("./supplier");
const claim_1 = require("./sub-models/role/claim");
const log_1 = require("./sub-models/general/log");
const coverageOverview_1 = require("./sub-models/general/coverageOverview");
const apikey_1 = require("./apikey");
let models = {
    user: user_1.User,
    supplier: supplier_1.Supplier,
    company: company_1.Company,
    employeePolicy: employeePolicy_1.EmployeePolicy,
    claim: claim_1.Claim,
    privateCondition: privateCondition_1.PrivateCondition,
    individualCondition: individualCondition_1.IndividualCondition,
    employeeCondition: employeeCondition_1.EmployeeCondition,
    companyInfo: companyInfo_1.CompanyInfo,
    coverageOverview: coverageOverview_1.CoverageOverview,
    group: group_1.Group,
    log: log_1.Log,
    insurance: insurance_1.Insurance,
    pension: pension_1.Pension,
    personInfo: personInfo_1.PersonInfo,
    employeePension: employeePension_1.EmployeePension,
    employeeInsurance: employeeInsurance_1.EmployeeInsurance,
    companyPension: companyPension_1.CompanyPension,
    companyInsurance: companyInsurance_1.CompanyInsurance,
    apikey: apikey_1.Apikey,
};
exports.superModel = function (type, content) {
    let model = models[type];
    return new model(content);
};
