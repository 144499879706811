"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeePolicy = void 0;
const class_validator_1 = require("class-validator");
const employeeCondition_1 = require("./sub-models/employeePolicy/employeeCondition");
const privateCondition_1 = require("./sub-models/employeePolicy/privateCondition");
const individualCondition_1 = require("./sub-models/employeePolicy/individualCondition");
const companyAdvisors_1 = require("./sub-models/general/companyAdvisors");
const absence_1 = require("./sub-models/employeePolicy/absence");
const employeeInsurance_1 = require("./sub-models/employeePolicy/employeeInsurance");
const employeePension_1 = require("./sub-models/employeePolicy/employeePension");
class EmployeePolicy {
    constructor(fields) {
        this.absence = new absence_1.Absence({});
        this.companyAdvisors = new companyAdvisors_1.CompanyAdvisors({});
        this.employeeCondition = new employeeCondition_1.EmployeeCondition({});
        this.individualCondition = new individualCondition_1.IndividualCondition({});
        this.privateCondition = new privateCondition_1.PrivateCondition({});
        this.insurances = [];
        this.pensions = [];
        this.advisorNote = '';
        this.comment = '';
        this.companyAdminComment = '';
        this.isActive = true;
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'absence':
                    this[key] = new absence_1.Absence(value);
                    break;
                case 'companyAdvisors':
                    this[key] = new companyAdvisors_1.CompanyAdvisors(value);
                    break;
                case 'employeeCondition':
                    this[key] = new employeeCondition_1.EmployeeCondition(value);
                    break;
                case 'privateCondition':
                    this[key] = new privateCondition_1.PrivateCondition(value);
                    break;
                case 'individualCondition':
                    this[key] = new individualCondition_1.IndividualCondition(value);
                    break;
                case 'insurances':
                    this[key] = value.map((insurance) => new employeeInsurance_1.EmployeeInsurance(insurance));
                    break;
                case 'pensions':
                    this[key] = value.map((pension) => new employeePension_1.EmployeePension(pension));
                    break;
                case 'createdAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'updatedAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    addEmployeeInsurance(insurance) {
        this.insurances.push(new employeeInsurance_1.EmployeeInsurance(insurance));
    }
    addEmployeePension(pension) {
        this.pensions.push(new employeePension_1.EmployeePension(pension));
    }
    removeEmployeeInsurance(insuranceId) {
        this.insurances = this.insurances.filter((insurance) => insurance.insuranceId !== insuranceId);
    }
    removeEmployeePension(pensionId) {
        this.pensions = this.pensions.filter((pension) => pension.pensionId !== pensionId);
    }
    toJSONObject() {
        return {
            absence: this.absence.toJSONObject(),
            employeeCondition: this.employeeCondition.toJSONObject(),
            privateCondition: this.privateCondition.toJSONObject(),
            individualCondition: this.individualCondition.toJSONObject(),
            pensions: this.pensions.map((pension) => pension.toJSONObject()),
            insurances: this.insurances.map((insurance) => insurance.toJSONObject()),
            companyId: this.companyId,
            employeeId: this.employeeId,
            advisorNote: this.advisorNote,
            companyAdvisors: this.companyAdvisors.toJSONObject(),
            id: this.id,
            isActive: this.isActive,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            comment: this.comment,
            companyAdminComment: this.companyAdminComment,
        };
    }
    toDatabase() {
        return {
            absence: this.absence.toDatabase(),
            employeeCondition: this.employeeCondition.toDatabase(),
            privateCondition: this.privateCondition.toDatabase(),
            individualCondition: this.individualCondition.toDatabase(),
            pensions: this.pensions.map((pension) => pension.toDatabase()),
            insurances: this.insurances.map((insurance) => insurance.toDatabase()),
            companyId: this.companyId,
            employeeId: this.employeeId,
            advisorNote: this.advisorNote,
            isActive: this.isActive,
            companyAdvisors: this.companyAdvisors.toDatabase(),
            comment: this.comment,
            companyAdminComment: this.companyAdminComment,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], EmployeePolicy.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], EmployeePolicy.prototype, "companyId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], EmployeePolicy.prototype, "employeeId", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeePolicy.prototype, "absence", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], EmployeePolicy.prototype, "companyAdvisors", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }) // Accessible for companyAdmin & Advisors & Admins
], EmployeePolicy.prototype, "employeeCondition", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }) // Accessible for Advisors & Admins
], EmployeePolicy.prototype, "individualCondition", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }) // Accessible for Advisors & Admins
], EmployeePolicy.prototype, "privateCondition", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }) // Accessible for Advisors & Admins
], EmployeePolicy.prototype, "insurances", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }) // Accessible for Advisors & Admins
], EmployeePolicy.prototype, "pensions", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], EmployeePolicy.prototype, "advisorNote", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], EmployeePolicy.prototype, "comment", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], EmployeePolicy.prototype, "companyAdminComment", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)()
], EmployeePolicy.prototype, "isActive", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], EmployeePolicy.prototype, "createdAt", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], EmployeePolicy.prototype, "updatedAt", void 0);
exports.EmployeePolicy = EmployeePolicy;
