"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Log = void 0;
const class_validator_1 = require("class-validator");
class Log {
    constructor(fields) {
        this.timestamp = new Date();
        this.person = {}; // The person that perform the operation
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'timestamp':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            id: this.id,
            description: this.description,
            operationType: this.operationType,
            timestamp: this.timestamp,
            data: this.data,
            person: this.person,
        };
    }
    toDatabase() {
        return {
            timestamp: this.timestamp,
            description: this.description,
            operationType: this.operationType,
            data: this.data,
            person: this.person,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], Log.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsOptional)()
], Log.prototype, "data", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Log.prototype, "operationType", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Log.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsDate)()
], Log.prototype, "timestamp", void 0);
exports.Log = Log;
